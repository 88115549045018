import React from 'react';
import { Container, Nav, NavDropdown, Navbar } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./pages/Home.css"


function Navigationbar() {
    return (
        <>
            <Navbar bg="light" expand="lg" className="navbar py-3">
                <Container>
                    <Navbar.Brand href="/" className='Title'>
                        <img
                            src="financial-services.svg"
                            width="30"
                            height="30"
                            className="d-inline-block align-top"
                            alt=""
                        />
                    </Navbar.Brand>
                    <div>
                        <Navbar.Brand href="/">2M Finserve Private Limited</Navbar.Brand>
                    </div>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ms-auto">
                            <Nav.Link href="/" style={{ fontSize: "1.3em" }}>Home</Nav.Link>
                            <Nav.Link href="/about-us" style={{ fontSize: "1.3em" }}>About</Nav.Link>
                            <NavDropdown title="Services" id="basic-nav-dropdown" style={{ fontSize: "1.3em" }}>
                                <NavDropdown.Item href="/project-finance">Project Finance</NavDropdown.Item>
                                <NavDropdown.Item href="/subsidy">Subsidy</NavDropdown.Item>
                                <NavDropdown.Item href="/term-loan">Term Loans</NavDropdown.Item>
                                {/* <NavDropdown.Divider /> */}
                                <NavDropdown.Item href="working-capital-loan">Working Capital Loans</NavDropdown.Item>
                                <NavDropdown.Item href="home-loan">Home Loans</NavDropdown.Item>
                                <NavDropdown.Item href="loan-against-property">Loan Against Property</NavDropdown.Item>
                                <NavDropdown.Item href="personal-loan">Personal Loan</NavDropdown.Item>
                            </NavDropdown>
                            <Nav.Link href="/contact-us-form" style={{ fontSize: "1.3em" }}>Contact Us</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    )
}

export default Navigationbar;