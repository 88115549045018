import React from 'react'
import { ButtonGroup, Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'


function ServiceBase() {

    let navigate = useNavigate();
    const routeChange = (url) => {
        let path = url;
        console.log(path);
        navigate(path);
    }

    return (
        <>
            <div className='container'>
                <div className='row custPad'>
                    <div className='col-md-9'><h2>Machinery Finance</h2></div>
                    <div className='col'>
                        <h2 className='text-center'>Services</h2>

                        <ButtonGroup className="groupedButtons" aria-label="small button group" orientation="vertical" variant="text">
                            <Button onClick={() => routeChange('/contact-us-form')} variant='contained' >Project Finance</Button>
                            <Button onClick={() => routeChange('/contact-us-form')} variant='outlined'>Term Loans</Button>
                            <Button onClick={() => routeChange('/contact-us-form')} variant='contained'>Home Loans</Button>
                            <Button onClick={() => routeChange('/contact-us-form')} variant='outlined'>Loan Against Property</Button>
                            <Button onClick={() => routeChange('/contact-us-form')} variant='contained'>Personal Loan</Button>
                            <Button onClick={() => routeChange('/contact-us-form')} variant='outlined'>Subsidy</Button>
                            <Button onClick={() => routeChange('/contact-us-form')} variant='contained'>Working Capital Loans</Button>
                        </ButtonGroup>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ServiceBase