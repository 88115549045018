import React from 'react'
import Corousel from '../Corousel'
import "./Home.css"
import CustCard from '../Card'
import service from '../static/service.svg'
import { Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import who from "../static/960x0.jpg"
import Finance from "../static/FinanceManagement.jpg"
import loyalty from '../static/loyalty-svgrepo-com.svg'
import money from '../static/money.svg'
import growth from '../static/growth.svg'
import handshake from '../static/hand-shake.svg'
import mechanical from '../static/mechanical.svg'

function Home() {
    window.scrollTo(0, 0);
    let navigate = useNavigate();
    const routeChange = () => {
        let path = '/contact-us-form';
        navigate(path);
    }

    return (
        <>
            <Corousel />
            <div className='custPad'>
                <div className='row'>
                    <h4>
                        Project Finance & Government Subsidies Consultant
                    </h4>
                </div>
                <div className='row rowPad'>
                    <div className='col-md-6'>
                        <img className="img-fluid" src={who} alt="..." />
                    </div>
                    <div className='col-md-6'>
                        <h4 style={{ paddingTop: "5%" }}>Who we are</h4>

                        <ul className="home-who-we-are">
                            <li>We are team of Professionals having rich experiences in field of Finance and Subsidy.</li>
                            <li>We have more than 500+ satisfied clients across India.</li>
                            <li>We deliver most cost effective and most beneficial financial solutions & Subsidy-Incentives to our clients.</li>
                            <li>We are sector agnostic and work across many industry verticals such as Dyes, Chemicals & Intermediates, Engineering, Textile, Plastic, Packing, Metals & Mining, Infrastructure, Manufacturing, Telecom, Logistics, Realty & Construction, FMCG Products, Pharmaceuticals, etc.</li>
                        </ul>

                    </div>
                </div>
            </div>
            <div className='sep'>
                <div className='custPadBot'>
                    <div className='col-md-12 centerAlign'>
                        <h5>CORE VALUE</h5>
                        <p>Strong Collaborative relationships based on a genuine understanding</p>
                    </div>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-4 custPadd'><CustCard title={'Client Oriented Approval'} src={service} /></div>
                            <div className='col-md-4 custPadd'><CustCard title={'Trust & Ethical Practice'} src={handshake} /></div>
                            <div className='col-md-4 custPadd'><CustCard title={'Transparency & Integrity'} src={mechanical} /></div>
                            <div className='col-md-4 custPadd'><CustCard title={'Commitment & Loyalty'} src={loyalty} /></div>
                            <div className='col-md-4 custPadd'><CustCard title={'Customer Satisfaction'} src={money} /></div>
                            <div className='col-md-4 custPadd'><CustCard title={'Ensure Growth'} src={growth} /></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bluesep">
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-6 custPadText'>
                            <div>
                                <h4>Project Finance</h4><br />
                                <p>Project finance is the funding (financing) of long-term infrastructure, industrial projects, and public services using a non-recourse or limited recourse financial structure. Project financing is a loan structure that relies primarily on the project’s cash flow for repayment, with the project’s assets, rights, and interests held as secondary collateral. It is especially attractive to the private sector because companies can fund major projects off-balance sheet.</p>
                            </div>
                            <div>
                                <h4>Subsidy</h4><br />
                                <p>Subsidies are cash grants or loans that the government gives to businesses. It encourages activities the government wishes to promote. The subsidy depends on the amount of the goods or services provided.</p>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div >
                                <img src={Finance} className="centerImg img-fluid" alt="..." />
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div >
                <div className='container col-md-12 centerAlignPad'>
                    <h5>Ready to get Started?</h5>
                    <p>We'd love to hear from you about your financial needs. We are currently accepting new clients.</p>
                    <Button variant="warning" onClick={routeChange} size="lg" active>Contact us</Button>{' '}
                </div>
            </div>
        </>
    )
}

export default Home