import React from 'react'

function ThankYou() {
    window.scrollTo(0, 0);
    return (
        <>
            <div className="custCenter">
                <div className="row paddcent">
                    <h1>Thank you for your Submission.</h1><br />
                    <p>We will contact you shortly</p>
                </div>
            </div>
        </>

    )
}

export default ThankYou