import React from 'react';
import About from './components/pages/about';
import Services from './components/pages/services';
import Navigationbar from './components/navbar';
import { Route, BrowserRouter, Routes, Router } from 'react-router-dom';
import Footer from './components/Footer';
import ContactUs from './components/pages/ContactUs';
import Home from './components/pages/Home';
import ServiceBase from './components/servicebase';
import HomeLoan from './components/pages/HomeLoan';
import ProjectFinance from './components/pages/ProjectFinance';
import TermLoans from './components/pages/TermLoans';
import LoanAgainstProperty from './components/pages/LoanAgainstProperty';
import PersonalLoan from './components/pages/PersonalLoan';
import Subsidy from './components/pages/Subsidy';
import WorkingCapitalLoans from './components/pages/WorkingCapitalLoans';
import ThankYou from './components/pages/ThankYou';


function App() {

    let hashHistory = Router.hashHistory;

    return (
        <>
            <Navigationbar />

            <BrowserRouter>
                <Routes history={hashHistory}>
                    <Route path="/" element={<Home />} />
                    <Route path="/about-us" element={<About />} />
                    <Route path="/services" element={<Services />} />
                    <Route path="/contact-us-form" element={<ContactUs />} />
                    <Route path="/service-base" element={<ServiceBase />} />
                    <Route path="/home-loan" element={<HomeLoan />} />
                    <Route path="/project-finance" element={<ProjectFinance />} />
                    <Route path="/term-loan" element={<TermLoans />} />
                    <Route path="/loan-against-property" element={<LoanAgainstProperty />} />
                    <Route path="/personal-loan" element={<PersonalLoan />} />
                    <Route path="/subsidy" element={<Subsidy />} />
                    <Route path="/working-capital-loan" element={<WorkingCapitalLoans />} />
                    <Route path="/thank-you" element={<ThankYou />} />
                </Routes>
            </BrowserRouter >

            < Footer />
        </>
    );
}

export default App;