import React from 'react'
import { Card, Button } from 'react-bootstrap';
import "./\\pages\\Home.css"

function CustCard(props) {
    return (
        <>
            <Card className='centerAlign'>
                <Card.Img className="imgCust" variant="top" src={props.src} />
                <Card.Body>
                    <Card.Title>{props.title}</Card.Title>
                </Card.Body>
            </Card>
        </>
    )
}

export default CustCard