import React from 'react'
import { ButtonGroup, Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'


function LoanAgainstProperty() {
    let navigate = useNavigate();
    const routeChange = (url) => {
        let path = url;
        console.log(path);
        navigate(path);
    }

    window.scrollTo(0, 0)

    return (
        <>

            <div className='container'>
                <div className='row paddServ'>
                    <div className='col-md-9'>
                        <h2>Loan Against Property</h2>
                        <ul className='padding-ul ulpadd'>
                            <li>
                                Loan against property (LAP), are basically loans provided by banks against the security of one’s own property.
                            </li>
                            <li>
                                LAP is designed to meet the financial needs of someone who already owns a house or multiple properties so as to get the best out of their assets.
                            </li>
                            <li>Its important to remember that the property which you are putting up for your loan should be free any encumbrance (i.e. it is not given as security for any purpose or any other loan).</li>
                        </ul>
                    </div>

                    <div className='col py-2'>
                        <h2 className='text-center'>Services</h2>

                        <ButtonGroup className="groupedButtons" aria-label="small button group" orientation="vertical" variant="text">
                            <Button onClick={() => routeChange('/project-finance')} variant='contained' >Project Finance</Button>
                            <Button onClick={() => routeChange('/term-loan')} variant='outlined'>Term Loans</Button>
                            <Button onClick={() => routeChange('/home-loan')} variant='contained'>Home Loans</Button>
                            <Button onClick={() => routeChange('/loan-against-property')} variant='outlined'>Loan Against Property</Button>
                            <Button onClick={() => routeChange('/personal-loan')} variant='contained'>Personal Loan</Button>
                            <Button onClick={() => routeChange('/subsidy')} variant='outlined'>Subsidy</Button>
                            <Button onClick={() => routeChange('/working-capital-loan')} variant='contained'>Working Capital Loans</Button>
                        </ButtonGroup>
                    </div>
                </div>
            </div>
            <hr />
            <div >
                <div className='container col-md-12 centerAlignPad'>
                    <h5>Ready to get Started?</h5>
                    <p>We'd love to hear from you about your financial needs. We are currently accepting new clients.</p>
                    <Button variant='contained' onClick={() => routeChange('/contact-us-form')} size="lg" >Contact us</Button>{' '}
                </div>
            </div>
        </>
    )
}

export default LoanAgainstProperty