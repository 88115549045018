import React from 'react'
import { ButtonGroup, Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'


function WorkingCapitalLoans() {
    let navigate = useNavigate();
    const routeChange = (url) => {
        let path = url;
        console.log(path);
        navigate(path);
    }

    window.scrollTo(0, 0)

    return (
        <>
            <div className='container'>
                <div className='row paddServ'>
                    <div className='col-md-9'>
                        <h2>Working Capital Loan</h2>
                        <ul className='padding-ul ulpadd'>
                            <li>
                                Working capital is the money that is used by a business on a day to day basis. It is the money that is required for the day to day operations of a business.
                            </li>
                            <li>
                                Without working capital, a business might not be able to operate efficiently, hence businesses might consider taking loans to meet this financial need. These loans that are used for the day to day operations of the business is known as working capital loan.
                            </li>
                            <li>A working capital loan is not taken for the expansion of a business; it is taken for the exclusive purpose of conducting business operations on a daily basis. This means it covers the expenses of human resources and the expenses of existing capital.</li>
                        </ul>
                    </div>

                    <div className='col py-2'>
                        <h2 className='text-center'>Services</h2>

                        <ButtonGroup className="groupedButtons" aria-label="small button group" orientation="vertical" variant="text">
                            <Button onClick={() => routeChange('/project-finance')} variant='contained' >Project Finance</Button>
                            <Button onClick={() => routeChange('/term-loan')} variant='outlined'>Term Loans</Button>
                            <Button onClick={() => routeChange('/home-loan')} variant='contained'>Home Loans</Button>
                            <Button onClick={() => routeChange('/loan-against-property')} variant='outlined'>Loan Against Property</Button>
                            <Button onClick={() => routeChange('/personal-loan')} variant='contained'>Personal Loan</Button>
                            <Button onClick={() => routeChange('/subsidy')} variant='outlined'>Subsidy</Button>
                            <Button onClick={() => routeChange('/working-capital-loan')} variant='contained'>Working Capital Loans</Button>
                        </ButtonGroup>
                    </div>
                </div>
            </div>
            <hr />
            <div >
                <div className='container col-md-12 centerAlignPad'>
                    <h5>Ready to get Started?</h5>
                    <p>We'd love to hear from you about your financial needs. We are currently accepting new clients.</p>
                    <Button variant='contained' onClick={() => routeChange('/contact-us-form')} size="lg" >Contact us</Button>{' '}
                </div>
            </div>
        </>
    )
}

export default WorkingCapitalLoans