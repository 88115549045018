import React from 'react'
import Carousel from 'react-bootstrap/Carousel'
import "./Corousel.css"
// import { Button } from 'react-bootstrap'
import project from "./static/Project-Finance.jpg"

function Corousel() {

    return (
        <>
            <Carousel>
                <Carousel.Item interval={2000} >
                    <img
                        className="d-block w-100"
                        src={project}
                        alt="Third slide"
                    />
                    <Carousel.Caption className='inner'>
                        <h3>Project Finance &amp; Government Subsidy</h3>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={project}
                        alt="First slide"
                    />
                    <Carousel.Caption className='inner'>
                        <h3>Get Direction From Our Expert CA, CS And Catch Your Goal</h3>
                        {/* <Button className='buttonSize' variant="warning" onClick={routeChange} size="lg" active>Contact us</Button>{' '} */}
                    </Carousel.Caption>

                </Carousel.Item>
            </Carousel>
        </>
    )
}

export default Corousel