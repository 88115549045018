import { React } from 'react'
import { useRef } from 'react';
import $ from 'jquery';
import { useNavigate } from 'react-router-dom'


function ContactForm() {
    const form = useRef();
    let navigate = useNavigate();
    const routeChange = (url) => {
        let path = url;
        console.log(path);
        navigate(path);
    }

    const sendEmail = (e) => {
        var checked_data = "";
        Array.from(form.current).forEach(element => {
            if (element.tagName.toLowerCase() === "input" && element.getAttribute('type') === "checkbox" && element.checked && element.value !== "on") {
                checked_data += element.value + ", ";
            }
        });
        checked_data = checked_data.substring(0, checked_data.length - 2);

        $.ajax({
            method: 'POST',
            url: 'https://formsubmit.co/ajax/2mfinserve@gmail.com',
            dataType: 'json',
            accepts: 'application/json',
            data: {
                Name: form.current.user_name.value,
                Email: form.current.user_email.value,
                Services_Opted: checked_data,
                Message: form.current.user_message.value,
                _subject: "Form Submission from " + form.current.user_name.value + "(" + form.current.user_email.value + ")"
            },
            success: (data) => console.log(data),
            error: (err) => console.log(err)
        });
        routeChange('/thank-you')

    };


    return (
        <>
            <div className='p-4' >
                <h4 className='text-center'>
                    Contact US
                </h4>
                <form ref={form} onSubmit={sendEmail}>
                    <div className="mb-2">
                        <label htmlFor="Name" className="form-label">Name</label>
                        <input type="text" className="form-control" id="Name" name="user_name" aria-describedby="emailHelp" required />
                    </div>
                    <div className="mb-2">
                        <label htmlFor="email" className="form-label">Email</label>
                        <input type="email" className="form-control" id="email" name="user_email" required />
                    </div>
                    <label htmlFor="service_type">Service Type</label><br />
                    <div id='service_type' className='m-2'>
                        <div className='d-flex justify-content-between'>
                            <div className='my-2'>
                                <input type="checkbox" className='mx-1' name="Service1" id="ProjectFinance" value="Project Finance" />
                                <label htmlFor="ProjectFinance">Project Finance</label><br />
                            </div>
                            <div className='my-2'>
                                <input type="checkbox" className='mx-1' name="Service2" id="Subsidy" value="Subsidy" />
                                <label htmlFor="Subsidy">Subsidy</label><br />
                            </div>
                            <div className='my-2'>
                                <input type="checkbox" className='mx-1' name="Service3" id="TermLoans" value="Term Loans" />
                                <label htmlFor="TermLoans">Term Loans</label><br />
                            </div>
                        </div>


                        <div className='d-flex justify-content-between'>
                            <div className='my-2'>
                                <input type="checkbox" className='mx-1' name="Service4" id="WCL" value="Working Capital Loan" />
                                <label htmlFor="WCL">Working Capital Loan</label><br />
                            </div>
                            <div className='my-2'>
                                <input type="checkbox" className='mx-1' name="Service5" id="HomeLoans" value="Home Loan" />
                                <label htmlFor="HomeLoans">Home Loan</label><br />
                            </div>
                        </div>

                        <div className='d-flex justify-content-between'>
                            <div className='my-2'>
                                <input type="checkbox" className='mx-1' name="Service6" id="LAP" value="Loan Against Property" />
                                <label htmlFor="LAP">Loan Against Property</label><br />
                            </div>
                            <div className='my-2'>
                                <input type="checkbox" className='mx-1' name="Service7" id="PL" value="Personal Loan" />
                                <label htmlFor="PL">Personal Loan</label><br />
                            </div>
                        </div>
                    </div>
                    <div className="mb-2">
                        <label htmlFor="message" className="form-label" >Message</label>
                        <textarea className="form-control" id="message" name="user_message" rows="4" required></textarea>
                    </div>
                    <div className="mb-2 form-check">
                        <input type="checkbox" className="form-check-input" id="exampleCheck1" required />
                    </div>
                    <label className="form-check-label custFont" name="concent" htmlFor="exampleCheck1">I hereby authorize to send notifications on SMS, Calls, Email and Whatsapp.
                        By submit the form, you agree to our Terms & Service and that you have read our Privacy Policy .</label>
                    <div className='padd'>
                        <input type="submit" id="submit" value="Submit" className="btn btn-outline-dark " />
                    </div>
                </form>
            </div>
        </>
    )
}

export default ContactForm

