import React from 'react'
import ContactForm from '../ContactForm'
import Location from '../static/location.svg'
import Phone from '../static/phone.svg'
import Message from '../static/message.svg'


function ContactUs() {
    window.scrollTo(0, 0);
    return (
        <div className='row Contact' >
            <div className='col-md-1 ' />
            <div className='col-md-5  '><ContactForm /></div>
            <div className='col-md-6 py-4 my-4'>
                <div className='row py-4 my-4 '>
                    <div className='col-md-4'>
                        <div className='w-100 d-flex justify-content-center my-4'>
                            <img src={Location} alt="React Logo" className='w-25 ' />
                        </div>
                    </div>
                    <div className='col-md-8'>
                        <h4>Address</h4>
                        <p>2M FINSERVE PRIVATE LIMITED
                            145, Macchala Magra, Opp. Hotel Paras Mahal, Udaipur, Rajasthan – 313001
                        </p>
                    </div>
                </div>
                <div className='row my-4'>
                    <div className='col-md-4'>
                        <div className='w-100 d-flex justify-content-center my-4'>
                            <img src={Phone} alt="React Logo" className='w-25 ' />
                        </div>
                    </div>
                    <div className='col-md-8'>
                        <h4>Phone</h4>
                        <p>+91-7976611080</p>
                    </div>
                </div>
                <div className='row my-4'>
                    <div className='col-md-4'>
                        <div className='w-100 d-flex justify-content-center my-2'>
                            <img src={Message} alt="React Logo" className='w-25 ' />
                        </div>
                    </div>
                    <div className='col-md-8'>
                        <h4>Email</h4>
                        <p>2mfinserve@gmail.com , mridulopc@gmail.com</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactUs