import React from "react";
import "./Footer.css";

function Footer() {
  return (
    <div className="Footer">
      <div className="Footerboxes row">
        <div className="Footerbox col-md-3">
          <h3>ABOUT US</h3>
          <div className="Footerboxbox">
            <p className="Footerboxboxtext">We are one of India’s Prestigious Financial Consultancy Company in the field of Project Finance, Working Capital, Term Loan, Housing Loan, Loan against property and Government subsidy. We have successfully served more than 500 Clients. We have a robust team of Chartered Accountants having rich experience in the project finance and subsidy. We understand your financial difficulties when it comes to starting your new business or expansion of existing business.</p>
          </div>
        </div>
        <div className="Footerbox col-md-3">
          <h3>Services</h3>
          <div className="Footerboxbox">
            <p className="Footerboxboxtext">Project Finance</p>
            <p className="Footerboxboxtext">Subsidy</p>
            <p className="Footerboxboxtext">Term Loans</p>
            <p className="Footerboxboxtext">Working Capital Loans</p>
            <p className="Footerboxboxtext">Home Loans</p>
            <p className="Footerboxboxtext">Loan Against Property</p>
            <p className="Footerboxboxtext">Personal Loan</p>
          </div>
        </div>
        <div className="Footerbox col-md-3">
          <h3>Contact-Us</h3>
          <div className="Footerboxbox">
            <p className="Footerboxboxtext">2M FINSERVE PRIVATE LIMITED</p>
            <br />
            <p className="Footerboxboxtext">145, Macchala Magra, Opp. Hotel Paras Mahal, Udaipur, Rajasthan – 313001</p>
            <br />
            <p className="Footerboxboxtext">+91-7976611080</p>
            <p className="Footerboxboxtext">2mfinserve@gmail.com, mridulopc@gmail.com</p>
          </div>
        </div>



      </div>

      <div className="Footertermsandcopyright">
        <div className="Footerterms">
          <p className="Footerterm">Terms of Use</p>
          <p className="Footerterm">Privacy Policies</p>
        </div>
        <div>
          <p className="Footerterm"> ©2022 2M Finserve Private Limited </p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
