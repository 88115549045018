import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
// import { ReactDOM } from 'react';
// import Navigationbar from '../navbar';'
import "./about.css"
import CustCard from '../Card'
import service from '../static/service.svg'
import { Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import Target from '../static/target.svg'
import Atom from '../static/atom.svg'
import finance from '../static/business-plan-close-up-contemporary-data.jpg'
import loyalty from '../static/loyalty-svgrepo-com.svg'
import money from '../static/money.svg'
import growth from '../static/growth.svg'
import handshake from '../static/hand-shake.svg'
import mechanical from '../static/mechanical.svg'



function About() {
    let navigate = useNavigate();
    const routeChange = () => {
        let path = '/contact-us-form';
        navigate(path);
    }
    return (
        <>
            <div className="row div-custom">
                <div className="col div-left">
                    <img src={finance} alt="..." className="img-thumbnail img-custom" />
                </div>
                <div className="col div-right">
                    <div className='child-div'>
                        <span className='custom-text text-xl-left'>
                            Best Subsidy Consultant in India.</span>
                    </div>
                    <p>
                        <span className='custom-span'>We are team of Professionals having rich experiences in field of Finance and Subsidy.Together, we will accelerate sustainable and inclusive growth.</span>
                    </p>
                </div>
            </div >
            <div className='container center-div'>
                <p className='text-left'>2M Finserve Private Limited is one of India’s Prestigious Financial Consultancy Company in the field of Project Finance, Working Capital, Term Loan, Housing Loan, Loan against property and Government subsidy. We have successfully served more than 500 Clients. We have a robust team of Chartered Accountants having rich experience in the project finance and subsidy. We understand your financial difficulties when it comes to starting your new business or expansion of existing business.</p>
                <p className='text-left'>Our objective is to transform dream business into reality by providing financial solutions in the form of project finance, machinery loan, working capital loan and all type of central and state government subsidy to our clients.
                </p>
                <p className='text-left'>If you are looking for financial assistance in terms of machinery loan, Term loan, Cash Credit, Export finance, Capital Subsidy, Interest subsidy, Electric subsidy and A-TUF subsidy, we are always ready to give you best guidance and assistance.</p>
            </div>
            <div className="bluesep">
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-6 custPadText'>
                            <div className='py-4 justify-content-center d-flex'>
                                <img src={Target} alt="React Logo" className='w-25' />
                            </div>
                            <div>
                                <h4 className=' justify-content-center d-flex w-100'>Mission</h4>
                                <p className=' justify-content-center d-flex w-100'>To deliver cost-effective and quality based financial solutions to exceed the expectations of clients.</p>
                            </div>
                        </div>
                        <div className='col-md-6 custPadText'>
                            <div className='py-4 justify-content-center d-flex'>
                                <img src={Atom} alt="React Logo" className='w-25' />
                            </div>
                            <div>
                                <h4 className=' justify-content-center d-flex w-100'>Vision</h4>
                                <p className=' justify-content-center d-flex w-100'>To attain best standards globally.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='sep'>
                <div className='custPadBot'>
                    <div className='col-md-12 centerAlign'>
                        <h5>CORE VALUE</h5>
                        <p>Strong Collaborative relationships based on a genuine understanding</p>
                    </div>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-4 custPadd'><CustCard title={'Client Oriented Approval'} src={service} /></div>
                            <div className='col-md-4 custPadd'><CustCard title={'Trust & Ethical Practice'} src={handshake} /></div>
                            <div className='col-md-4 custPadd'><CustCard title={'Transparency & Integrity'} src={mechanical} /></div>
                            <div className='col-md-4 custPadd'><CustCard title={'Commitment & Loyalty'} src={loyalty} /></div>
                            <div className='col-md-4 custPadd'><CustCard title={'Customer Satisfaction'} src={money} /></div>
                            <div className='col-md-4 custPadd'><CustCard title={'Ensure Growth'} src={growth} /></div>
                        </div>
                    </div>
                </div>
            </div>
            <div >
                <div className='container col-md-12 centerAlignPad'>
                    <h5>Ready to get Started?</h5>
                    <p>We'd love to hear from you about your financial needs. We are currently accepting new clients.</p>
                    <Button variant="warning" onClick={routeChange} size="lg" active>Contact us</Button>{' '}
                </div>
            </div>
        </>

    );
}

export default About;